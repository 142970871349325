<template>
  <div class="">
    <nav class="navbar navbar-light bg-white fixed-top shadow-sm py-0">
      <a class="navbar-brand fw-bold">
        <img
          src="@/assets/images/side-bar-logo-black.png"
          alt=""
          class=""
          width="200"
          length="200"
        />
        Electronic Certification, Authentication and Verification Portal (eCAV)
      </a>
      <span class="navbar-text">
        <img :src="hei.logo" alt="" class="mx-1" width="50" length="50" />
        <strong>{{ $store.getters.getUserFullName }} | {{ hei.abbrev }}</strong>
        <button
          class="btn btn-link text-dark mx-3"
          @click.prevent="handleLogout"
        >
          Logout
        </button>
      </span>
    </nav>
    <br />
    <br />
    <div class="container mt-5 px-5">
      <div class="card rounded-corner shadow-sm mt-4">
        <div class="card-body">
          <ul class="nav nav-tabs" id="nstp-client-tabs">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="Announcements"
                @click.prevent="tabActive('Announcements')"
                href="#"
              >
                <i class="bi bi-megaphone-fill me-2"></i> Announcements</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                id="Applications"
                @click.prevent="tabActive('Applications')"
                href="#"
                ><i class="bi bi-person-add me-2"></i>CAV Applications</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link disabled"
                id="Applications"
                @click.prevent="tabActive('SpecialOrder')"
                href="#"
                ><i class="bi bi-hourglass-split me-2"></i>Special Order (Coming
                soon)</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="my-3">
        <component v-bind:is="currentTab"> </component>
      </div>
    </div>
  </div>
</template>

<script>
import Applications from './components/Applications.vue';
import Announcements from './components/Announcements.vue';

export default {
  name: 'Main',
  components: {
    Applications,
    Announcements,
  },
  data() {
    return {
      currentTab: 'Announcements',
      hei: {},
    };
  },
  methods: {
    tabActive(element) {
      const el = document.getElementById(this.currentTab);
      el.classList.remove('active');
      const selectedTab = document.getElementById(element);
      selectedTab.classList.add('active');
      this.currentTab = element;
    },

    getHeiPrograms() {
      axios
        .get('api/hei-programs/' + this.$store.state.user?.hei_code)
        .then((response) => {
          this.heiPrograms = response.data;
        })
        .catch((error) => {});
    },

    authCheck() {
      axios
        .get('api/check-auth')
        .then((response) => {
          if (!response.data) {
            this.$store.commit('setAuthentication', false);
            this.$store.commit('setUser', null);
            this.$router.push({ name: 'Login' });
          }
        })
        .catch((error) => {});
    },
    getHeiInfo() {
      axios
        .get('api/heis/' + this.$store.state.user.hei_code)
        .then((response) => {
          this.hei = response.data;
        })
        .catch((error) => {});
    },

    handleLogout() {
      axios.post('/logout').then((response) => {
        this.$store.commit('logout', false);
        this.$router.push({ name: 'Login' });
      });
    },
  },
  mounted() {
    this.authCheck();
    this.getHeiInfo();
  },
};
</script>
