<template>
  <div>
    <div class="modal" data-backdrop="static" id="application-form-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
            <div class="modal-body">
              <div v-if="showMsg" class="alert alert-danger">
                We only accept applications during office hours. (Monday to
                Friday - 8:00 AM to 5:00 PM)
              </div>

              <div class="container">
                <div class="mb-3">
                  <label for="" class="form-label">Full Name</label>
                  <div class="input-group">
                    <input
                      name="firstname"
                      v-model="form.firstname"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="First Name"
                      required
                    />
                    <input
                      name="middlename"
                      v-model="form.middlename"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="Middle Name"
                    />
                    <input
                      name="lastname"
                      v-model="form.lastname"
                      type="text"
                      class="form-control text-uppercase"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Program</label>
                  <select
                    name="program-id"
                    class="form-select col-auto"
                    v-model="form.program_id"
                  >
                    <option hidden disabled selected value="">Choose</option>
                    <option
                      v-for="program in heiPrograms"
                      :value="program.program_id"
                      :key="program.id"
                    >
                      {{ program.program_name }}
                    </option>
                  </select>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label">Student Type</label>
                      <select
                        name="student-type"
                        class="form-select"
                        v-model="form.student_type"
                        id=""
                        required
                      >
                        <option value="" selected disabled hidden>
                          Choose
                        </option>
                        <option value="1">Graduate (GR)</option>
                        <option value="2">Unit Earner (UE)</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label">Sector</label>
                      <select
                        v-model="form.sector"
                        name="sector"
                        class="form-select"
                        required
                      >
                        <option value="" selected hidden disabled>
                          Choose
                        </option>
                        <option value="1">N/A</option>
                        <option value="2">Indigenous People (IP)</option>
                        <option value="3">Person with Disability (PWD)</option>
                        <option value="4">Senior Citizen (SC)</option>
                        <option value="5">Solo Parent (SP)</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Email (Optional)</label>

                  <input
                    name="email"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  />
                </div>

                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label">Sex</label>

                      <select
                        v-model="form.sex"
                        name="sex"
                        class="form-select"
                        required
                      >
                        <option value="" selected hidden disabled>
                          Choose
                        </option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label">Date of Birth</label>
                      <input
                        name="date-of-birth"
                        v-model="form.date_of_birth"
                        type="date"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Mobile Number</label>

                  <input
                    name="contact-no"
                    v-model="form.contact_no"
                    type="text"
                    class="form-control"
                    placeholder="Enter 11-digit mobile number"
                    maxlength="11"
                    required
                  />
                  <small class="form-text text-muted"
                    >SMS notification message will be sent to this number.
                  </small>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label">Apply for</label>

                      <select
                        name="purpose"
                        v-model="form.purpose"
                        class="form-select"
                        required
                      >
                        <option value="" selected hidden disabled>
                          Choose
                        </option>
                        <option value="1">Local</option>
                        <option value="2">Abroad</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label class="form-label">CTC (Optional)</label>

                      <input
                        name="ctc-qty"
                        v-model="form.ctc_qty"
                        type="number"
                        class="form-control"
                        placeholder="Leave blank if no CTC"
                      />
                    </div>
                  </div>

                  <div class="mb-3" v-if="form.purpose == 2">
                    <label class="form-label">Country</label>
                    <select
                      name="country-id"
                      class="form-control"
                      v-model="form.country_id"
                      required
                    >
                      <option value="" selected disabled hidden>Choose</option>
                      <option v-for="c in countries" :key="c.id" :value="c.id">
                        {{ c.country_name }}
                      </option>
                    </select>
                    <small class="text-danger mb-2"
                      >Please submit the Hard Copy of the Documents
                      (Original/Aunthenticated - depends of the student) to CHED
                      Caraga for DFA Authentication.</small
                    >
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Purpose</label>
                  <select
                    name="reason"
                    class="form-select"
                    v-model="form.reason"
                    required
                  >
                    <option value="" selected disabled hidden>Choose</option>
                    <option value="Employment">For Employment</option>
                    <option value="Study/Enrollment">
                      For Study/Enrollment
                    </option>
                    <option value="Board Examination">
                      For Board Examination
                    </option>

                    <option value="Promotion">For Promotion</option>
                    <option value="Retirement">For Retirement</option>
                    <option value="6">Others</option>
                  </select>

                  <div class="mt-2" v-if="form.reason == '6'">
                    <input
                      name="others"
                      type="text"
                      class="form-control"
                      placeholder="Pls. specify here"
                      v-model="form.others"
                      required
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label"
                    >Documentary Requirements <strong>(PDF only)</strong></label
                  >
                  <div class="input-group">
                    <input
                      id="file-input"
                      type="file"
                      accept="application/pdf"
                      class="form-control"
                      @change="handleFileUploadEvent"
                      required
                    />
                  </div>
                  <small class="form-text text-muted"
                    >Max. file size: 20 MB</small
                  >
                </div>

                <div class="form-check">
                  <input
                    name="checkbox"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    v-model="checkbox"
                  />
                  <label class="form-check-label">
                    All entries have been verified and confirmed as accurate.
                  </label>
                </div>

                <div v-if="hasReachedMax" class="alert alert-danger mt-3">
                  {{ hasReachedMaxMsg }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                id="submit-btn"
                type="submit"
                class="btn btn-primary"
                :disabled="!checkbox"
                v-show="showApplyBtn"
              >
                Submit application
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    heiPrograms: {
      type: Array,
    },
  },
  data() {
    return {
      checkbox: false,
      countries: [],
      hasReachedMax: false,
      hasReachedMaxMsg: '',
      errors: [],
      showApplyBtn: false,
      showMsg: false,
      modal: {},
      form: new Form({
        lastname: '',
        firstname: '',
        middlename: '',
        student_type: '',
        email: '',
        sex: '',
        date_of_birth: '',
        contact_no: '',
        sector: '',
        purpose: '',
        reason: '',
        others: '',
        country_id: '',
        program_id: '',
        ctc_qty: '',
        file: '',
        school_graduated: this.$store.state.user.hei_code,
      }),
    };
  },
  methods: {
    open() {
      this.modal = new bootstrap.Modal(
        document.getElementById('application-form-modal')
      );
      this.modal.toggle();
      this.form.reset();
      document.getElementById('file-input').value = null;
      this.checkbox = false;
      this.getCountries();
      this.checkDateTime();
      this.hasReachedMax = false;
    },

    handleFileUploadEvent(event) {
      this.form.file = event.target.files[0];
    },

    handleSubmit() {
      let b = document.getElementById('submit-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span>`;

      let fd = new FormData();

      for (let key in this.form) {
        fd.append(key, this.form[key]);
      }

      axios
        .post('api/cav-applications', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          b.innerHTML = 'Submit application';
          b.disabled = false;
          this.$emit('getApplications');
          document.getElementById('file-input').value = null;
          this.modal.toggle();
        })
        .catch((error) => {
          b.innerHTML = 'Submit application';
          b.disabled = false;
          this.hasReachedMax = true;
          this.hasReachedMaxMsg = error.response.data.message;
        });
    },

    checkDateTime() {
      axios
        .get('api/check-date-time')
        .then((res) => {
          this.showApplyBtn = res.data;
          this.showApplyBtn ? (this.showMsg = false) : (this.showMsg = true);
        })
        .catch(() => {});
    },

    getCountries() {
      axios
        .get('api/countries/all')
        .then((response) => {
          this.countries = response.data;
        })
        .catch((error) => {
          //toastr.error('Oops! Something went wrong.', 'Error');
        });
    },
  },
  mounted() {
    this.checkDateTime();
    this.getCountries();
  },
};
</script>
