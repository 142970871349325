<template>
  <div>
    <div v-if="loader" class="d-flex justify-content-center">
      <div class="spinner-border spinner-border-sm" role="status"></div>
    </div>

    <div v-if="showNoPaymentMsg" class="text-center">
      <span>Payment not found.</span>
    </div>
    <table
      v-if="!loader && showNoPaymentMsg == false"
      class="table table-bordered"
    >
      <tbody>
        <tr>
          <td>Payment ID</td>
          <th>{{ payment.id }}</th>
        </tr>
        <tr>
          <td>Amount Paid</td>
          <th>{{ payment.amount }}</th>
        </tr>
        <tr>
          <td>Payment Conf. Date</td>
          <th>{{ payment.receipt_date | standardDate }}</th>
        </tr>
        <tr>
          <td>LBP Conf. No.</td>
          <th>{{ payment.receipt_no }}</th>
        </tr>
        <tr>
          <td>LBP Ref. No.</td>
          <th>{{ payment.reference_no }}</th>
        </tr>
        <tr>
          <td>Updated on</td>
          <th>{{ payment.updated_at | dateTime }}</th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PaymentDetailsTab',
  props: {
    application: {
      type: Object,
    },
  },
  data() {
    return {
      payment: {},
      showNoPaymentMsg: false,
      loader: false,
    };
  },
  methods: {
    getPaymentDetails() {
      if (!this.hasPayment()) {
        this.showNoPaymentMsg = true;
        return;
      }

      this.showNoPaymentMsg = false;
      this.loader = true;

      axios
        .get('api/cav-payments/' + this.application.payment_id)
        .then((res) => {
          this.payment = res.data;
          this.loader = false;
        })
        .catch((err) => {
          //toastr.error('Unable to fetch payment details.', 'Error');
          this.loader = false;
        });
    },
    hasPayment() {
      return (
        this.application.payment_id !== null &&
        this.application.payment_id !== ''
      );
    },
  },
  mounted() {
    this.getPaymentDetails();
  },
};
</script>
