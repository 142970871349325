<template>
  <div>
    <div class="card mb-3 shadow-sm rounded-corner">
      <div class="card-body">
        <div class="">
          <div class="row">
            <div class="col-2">
              <div class="mb-3">
                <label for="" class="form-label">App No</label>
                <input
                  type="search"
                  class="form-control"
                  placeholder="8-Digit App No."
                  v-model="params.id"
                  maxlength="8"
                />
              </div>
            </div>

            <div class="col-2 ps-1">
              <div class="mb-3">
                <label for="" class="form-label">Last Name</label>
                <input
                  type="search"
                  class="form-control"
                  placeholder="Student Last Name"
                  v-model="params.lastname"
                />
              </div>
            </div>

            <div class="col-3 ps-1">
              <div class="mb-3">
                <label for="" class="form-label">Program</label>
                <select
                  class="form-select col-auto"
                  v-model="params.program_id"
                >
                  <option selected value="">All</option>
                  <option
                    v-for="program in heiPrograms"
                    :value="program.program_id"
                    :key="program.hei_program_id"
                  >
                    {{ program.program_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-1 ps-1">
              <div class="mb-3">
                <label for="" class="form-label">Purpose</label>
                <select class="form-select col-auto" v-model="params.purpose">
                  <option selected value="">All</option>
                  <option value="Local">Local</option>
                  <option value="Abroad">Abroad</option>
                </select>
              </div>
            </div>

            <div class="col-2 ps-1">
              <div class="mb-3">
                <label for="" class="form-label">Status</label>
                <select class="form-select col-auto" v-model="params.status_id">
                  <option selected value="">All</option>
                  <option
                    v-for="status in statuses"
                    :value="status.id"
                    :key="status.id"
                  >
                    {{ status.description }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-auto ps-1">
              <div class="mb-3">
                <label class="form-label">Date Applied</label>
                <input
                  v-model="params.created_at"
                  type="date"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="">
            <button
              type="button"
              class="btn btn-primary me-3"
              :disabled="searchLoader"
              @click.prevent="
                getApplications(DEFAULT_URL, {
                  tableLoader: { enabled: false },
                  searchLoader: { enabled: true },
                })
              "
            >
              <span
                v-if="searchLoader"
                class="spinner-border spinner-border-sm"
              ></span>
              <span v-else><i class="bi bi-search me-2"></i> Search</span>
            </button>

            <button
              v-if="$store.state.user.role == 'Registrar'"
              class="btn btn-outline-primary"
              @click.prevent="openApplicationFormModal"
            >
              <i class="bi bi-folder-plus me-2"></i> Submit new application
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card rounded-corner shadow-">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="row">
            <div class="col-auto my-auto"><span for="">No. of rows</span></div>
            <div class="col">
              <select
                name=""
                id=""
                class="form-select ml-1"
                v-model="params.per_page"
                @change="
                  getApplications(DEFAULT_URL, {
                    tableLoader: { enabled: false },
                    searchLoader: { enabled: false },
                  })
                "
              >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          <div></div>
          <div class="d-flex justify-content-right">
            <div class="mx-2 my-auto">
              <label for="" class="col-form-label">{{
                `Showing ${pagination.from || 0}-${pagination.to || 0} of ${
                  pagination.total || 0
                } entries`
              }}</label>
            </div>

            <nav>
              <ul class="pagination mb-0">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getApplications(pagination.prev_page_url, {
                        searchLoader: { enabled: false },
                        tableLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only"></span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      getApplications(pagination.next_page_url, {
                        searchLoader: { enabled: false },
                        tableLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only"></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="table-responsive">
          <table
            id="applications-table"
            class="table table-hover table-bordered table-sm"
          >
            <thead>
              <tr>
                <th>APP NO</th>
                <th>NAME</th>
                <th>PROGRAM</th>
                <th>DATE APPLIED</th>

                <th>STATUS</th>
              </tr>
            </thead>
            <tbody class="text-uppercase">
              <tr class="text-center" v-show="tableLoader">
                <td colspan="15">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading...
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="!applications.length && !tableLoader"
              >
                <td colspan="15">No application/s found.</td>
              </tr>
              <tr
                v-for="app in applications"
                :key="app.id"
                @click.stop="openApplicationDetailsModal(app)"
              >
                <th>{{ app.id }}</th>

                <td>
                  {{ app.firstname }} {{ app.middlename }} {{ app.lastname }}
                </td>

                <td>{{ app.program_name }}</td>
                <td>{{ app.created_at | completeDateTime }}</td>

                <td>
                  <span
                    :class="[
                      'badge ',
                      app.status == 'RELEASED' ? 'bg-success' : 'bg-secondary',
                    ]"
                    >{{ app.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ApplicationFormModal
      ref="ApplicationFormModal"
      :heiPrograms="heiPrograms"
      @getApplications="
        getApplications(DEFAULT_URL, {
          searchLoader: { enabled: false },
          tableLoader: { enabled: false },
        })
      "
    ></ApplicationFormModal>

    <ApplicationDetailsModal
      ref="ApplicationDetailsModal"
    ></ApplicationDetailsModal>
  </div>
</template>

<script>
import ApplicationFormModal from '../components/ApplicationFormModal.vue';
import ApplicationDetailsModal from '../components/ApplicationDetailsModal.vue';
import { statuses } from '../../common/statuses.js';

export default {
  name: 'Applications',
  components: {
    ApplicationFormModal,
    ApplicationDetailsModal,
  },
  data() {
    return {
      prefix: 'https://drive.google.com/file/d/',
      applications: [],
      tableLoader: false,
      searchLoader: false,
      pagination: {},
      DEFAULT_URL: 'api/cav-applications',
      dismiss: false,
      heiPrograms: [],
      statuses: statuses,
      params: {
        id: '',
        lastname: '',
        status_id: '',
        student_type: '',
        purpose: '',
        created_at: '',
        per_page: 10,
        program_id: '',
      },
    };
  },
  methods: {
    getApplications(page_url, options) {
      this.tableLoader = options.tableLoader.enabled;
      this.searchLoader = options.searchLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      axios
        .get(page_url, {
          params: this.params,
        })
        .then((res) => {
          this.applications = res.data.data;
          this.makePagination(res.data);
          this.tableLoader = false;
          this.searchLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
          this.searchLoader = false;
          toastr.error(
            'Unable to retrieve application/s.',
            'Oops! Something went wrong.'
          );
        });
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },
    getHeiPrograms() {
      axios
        .get('api/hei-programs/' + this.$store.state.user?.hei_code)
        .then((response) => {
          this.heiPrograms = response.data;
        })
        .catch((error) => {});
    },
    openApplicationFormModal() {
      this.$refs.ApplicationFormModal.open();
    },
    openApplicationDetailsModal(application) {
      this.$refs.ApplicationDetailsModal.open(application);
    },
  },
  mounted() {
    this.getHeiPrograms();
    this.getApplications(this.DEFAULT_URL, {
      searchLoader: { enabled: false },
      tableLoader: { enabled: true },
    });
  },
};
</script>

<style scoped>
#applications-table tr {
  cursor: pointer !important;
}
</style>
