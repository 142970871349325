export const statuses = [
  {
    id: '2',
    description: 'For Payment Validation',
  },
  {
    id: '3',
    description: 'For Verification',
  },
  {
    id: '4',
    description: 'For Process',
  },
  {
    id: '5',
    description: 'For Release',
  },
  {
    id: '6',
    description: 'Released',
  },
  {
    id: '7',
    description: 'Pending',
  },
];
