<template>
  <div>
    <div class="table-responsive">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td width="30%">Last Name</td>
            <th>{{ application.lastname }}</th>
          </tr>
          <tr>
            <td>First Name</td>
            <th>{{ application.firstname }}</th>
          </tr>
          <tr>
            <td>Middle Name</td>
            <th>{{ application.middlename }}</th>
          </tr>
          <tr>
            <td>Program</td>
            <th>{{ application.program_name }}</th>
          </tr>
          <tr>
            <td>Student Type</td>
            <th>{{ application.student_type }}</th>
          </tr>
          <tr>
            <td>Purpose</td>
            <th>{{ application.purpose }}</th>
          </tr>
          <tr>
            <td>Sex</td>
            <th>{{ application.sex }}</th>
          </tr>
          <tr>
            <td>Date of Birth</td>
            <th>{{ application.date_of_birth | standardDate }}</th>
          </tr>
          <tr>
            <td>Email</td>
            <th>{{ application.email }}</th>
          </tr>
          <tr>
            <td>Contact No</td>
            <th>{{ application.contact_no }}</th>
          </tr>
          <tr>
            <td>CTC Quantity</td>
            <th>{{ application.ctc_qty }}</th>
          </tr>
          <tr>
            <td>Current Remarks</td>
            <th>{{ application.remarks }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationDetailsTab',
  props: {
    application: {
      type: Object,
    },
  },
};
</script>
