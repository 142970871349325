<template>
  <div>
    <div class="alert alert-warning shadow-sm rounded-corner" role="alert">
      <i class="bi bi-exclamation-circle-fill me-2"></i>Please take a moment to
      review important announcements here to minimize unnecessary inquiries and
      prevent delays in your applications.
    </div>

    <div class="list-group rounded-corner shadow-sm">
      <a
        class="list-group-item list-group-item-action px-4 py-3"
        aria-current="true"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            <span class="badge bg-success me-1"> NEW</span>
            Important Announcement: System Migration and Upcoming Orientation
            (eCAV Nationwide Implementation | https://ecav.ched.gov.ph)
          </h5>
        </div>
        <p class="mb-1">
          We are excited to announce that we will soon be migrating to a new and
          improved system, which will be rolled out on a nationwide scale. To
          ensure a smooth transition, we will be conducting orientations for all
          Higher Education Institutions (HEIs).
        </p>

        <small class="text-muted">August 13, 2024</small>
      </a>
      <a
        class="list-group-item list-group-item-action px-4 py-3"
        aria-current="true"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">eCAV Portal v.2</h5>
        </div>
        <p class="mb-1">
          Welcome to the new eCAV portal. We have updated the user interface and
          components for a better user experience.
        </p>

        <div>
          <p>Some updates:</p>
          <ul>
            <li>Added <strong>Program</strong> in the application form</li>
            <li>Can view application <strong>Status/Trail Logs</strong></li>
          </ul>

          <p class="">
            Watch the
            <a
              href="https://drive.google.com/file/d/1ZQAV3kW5jXkU79CKhu-vLTIuvVprSIQz/view"
              target="_blank"
              class="fw-bold"
              >eCAV Tutorial Video</a
            >
            for instructions on navigating through the portal.
          </p>

          <div>
            Should you encounter any issues, kindly complete this
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfCW65mC9LBS-kuyhPmspdG9oQ1PF5i3PeeVVLDik1u0R6VJg/viewform"
              target="_blank"
              >form</a
            >
            for assistance.
          </div>
        </div>

        <small class="text-muted">February 01, 2024</small>
      </a>
      <a
        class="list-group-item list-group-item-action px-4 py-3"
        aria-current="true"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            <span class="badge bg-danger me-1">
              <i class="bi bi-exclamation-diamond-fill me-1"></i>
              IMPORTANT</span
            >
            Validity period for CAV application: 5 days
          </h5>
        </div>
        <p class="mb-1">
          Effective Jan. 5, 2024, applications submitted must be
          <strong> paid within (5) five days </strong> of application. Failure
          to pay will result in automatic deletion of the application. This step
          is crucial to prevent unnecessary data accumulation and maintain the
          efficiency of our processing system.
        </p>
        <small class="text-muted">January 5, 2024</small>
      </a>
      <a class="list-group-item list-group-item-action px-4 py-3">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            <span class="badge bg-danger me-1">
              <i class="bi bi-exclamation-diamond-fill me-1"></i>
              IMPORTANT</span
            >
            Using the Correct 8-Digit CAV Application Number in Payment
            Transactions
          </h5>
        </div>
        <p class="mb-1">
          All payment transaction must include a unique and valid 8-Digit CAV
          Application Number. Any invalid or duplicate application numbers will
          not be processed. These measures are in place to streamline the
          payment process and maintain data accuracy.
        </p>
        <small class="text-muted">November 03, 2023</small>
      </a>
      <a class="list-group-item list-group-item-action px-4 py-3">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">No More Proof of Payment Upload Required</h5>
        </div>
        <p class="mb-1">
          You will no longer be required to upload proof of payment to our
          portal to proceed with your transactions. Enjoy the convenience.
        </p>
        <small class="text-muted">September 20, 2023</small>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Announcements',
  data() {
    return {};
  },
};
</script>
