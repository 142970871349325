<template>
  <div>
    <div>
      <div class="modal" id="application-details-modal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="container">
                <div class="d-flex justify-content-between mb-3">
                  <div class="my-auto">
                    <div>
                      <h5 class="mb-1" style="font-size: 1.5rem">
                        {{ application.lastname }}, {{ application.firstname }}
                        {{ application.middlename }}
                      </h5>
                      <p>
                        App No: <strong>{{ application.id }}</strong>
                      </p>

                      <p class="">{{ application.abbrev }}</p>
                    </div>
                  </div>
                  <i class="bi bi-person-circle" style="font-size: 50px"></i>
                </div>

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <button
                    class="nav-link active"
                    id="ApplicationDetailsTab"
                    type="button"
                    role="tab"
                    @click.prevent="tabActive('ApplicationDetailsTab')"
                  >
                    <i class="bi bi-person-vcard me-2"></i> Application Details
                  </button>

                  <button
                    class="nav-link"
                    id="PaymentDetailsTab"
                    type="button"
                    role="tab"
                    @click.prevent="tabActive('PaymentDetailsTab')"
                  >
                    <i class="bi bi-wallet-fill me-2"></i> Payment
                  </button>

                  <button
                    class="nav-link"
                    id="FileManagerTab"
                    type="button"
                    role="tab"
                    @click.prevent="tabActive('FileManagerTab')"
                  >
                    <i class="bi bi-archive-fill me-2"></i> File Manager
                  </button>

                  <button
                    class="nav-link"
                    id="StatusLogTab"
                    type="button"
                    role="tab"
                    @click.prevent="tabActive('StatusLogTab')"
                  >
                    <i class="bi bi-graph-up-arrow me-2"></i> Status Log
                  </button>
                </ul>

                <div class="mt-3">
                  <component
                    v-bind:is="currentTab"
                    :application="application"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationDetailsTab from '../components/ApplicationDetailsTab.vue';
import PaymentDetailsTab from '../components/PaymentDetailsTab.vue';
import FileManagerTab from '../components/FileManagerTab.vue';
import StatusLogTab from '../components/StatusLogTab.vue';

export default {
  name: 'ApplicationDetailsModal',
  components: {
    ApplicationDetailsTab,
    PaymentDetailsTab,
    FileManagerTab,
    StatusLogTab,
  },
  props: {},
  data() {
    return {
      currentTab: 'ApplicationDetailsTab',
      application: new Form({
        id: '',
        lastname: '',
        firstname: '',
        middlename: '',
        program_name: '',
        student_type: '',
        sex: '',
        date_of_birth: '',
        purpose: '',
        email: '',
        contact_no: '',
        ctc_qty: '',
        remarks: '',
        payment_id: '',
      }),
    };
  },
  methods: {
    open(application) {
      this.tabActive('ApplicationDetailsTab');
      var modal = new bootstrap.Modal(
        document.getElementById('application-details-modal')
      );
      modal.toggle();
      this.application.fill(application);
    },

    tabActive(element) {
      const el = document.getElementById(this.currentTab);
      el.classList.remove('active');
      const selectedTab = document.getElementById(element);
      selectedTab.classList.add('active');
      this.currentTab = element;
    },
  },
};
</script>
